







import { Component, Vue } from "vue-property-decorator";
import AdminAnalyticsDiagram from "@/components/admin/user/analytics/AdminAnalyticsDiagram.vue";

@Component({
    components: {
        AdminAnalyticsDiagram,
    },
})
export default class AdminAnalyticsManagement extends Vue {}
