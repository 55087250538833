



























































































































































































import { Component, Vue } from "vue-property-decorator";
import axios, { APIResponse } from "@/plugins/axios";
import { Analytics } from "@/types/user/analytics";
import AlertModule, { AlertType } from "@/store/modules/alert";
import { DataTableHeader } from "vuetify/types";

@Component
export default class AdminAnalyticsDiagram extends Vue {
    private searchText = "";
    private dates = [];
    private analytics: Analytics[] = [];
    private filterTimestamps = [];

    private get analyticsTableHeaders(): DataTableHeader[] {
        return [
            {
                text: "Betriebssystem",
                value: "os",
            },
            {
                text: "Browser",
                value: "browser",
            },
            {
                text: "Sprache",
                value: "language",
            },
            {
                text: "Besuchte Route",
                value: "route",
            },
            {
                text: "Zeitstempel",
                value: "timestamp",
            },
            {
                text: "Benutzer ID",
                value: "userId",
            },
            {
                text: "Benutzer Information",
                value: "userAgent",
            },
        ];
    }

    private get analyticsTableValues() {
        return this.analytics
            .map((a) => ({
                ...a,
                os: a.os.map((o) => o.name).join(", "),
                browser: a.browser.map((b) => b.name).join(", "),
                userId: a.userId !== null ? a.userId : "Unbekannt",
            }))
            .filter((a) => {
                const analyticsDate = new Date(a.timestamp);
                analyticsDate.setHours(0, 0, 0);

                let filterFromDate: Date | null = new Date(
                    this.filterTimestamps[0]
                );
                filterFromDate.setHours(0, 0, 0);

                let filterToDate: Date | null = new Date(
                    this.filterTimestamps[1]
                );
                filterToDate.setHours(0, 0, 0);

                if (
                    !isNaN(filterFromDate.valueOf()) &&
                    analyticsDate < filterFromDate
                ) {
                    return false;
                }
                if (
                    !isNaN(filterToDate.valueOf()) &&
                    analyticsDate > filterToDate
                ) {
                    return false;
                }

                return true;
            });
    }

    private get rankedOperatingSystems(): string[] {
        return this.rankEntries(
            this.analytics.flatMap((a) => a.os.map((o) => o.name))
        );
    }

    private get rankedBrowsers(): string[] {
        return this.rankEntries(
            this.analytics.flatMap((a) => a.browser.map((b) => b.name))
        );
    }
    private get rankedLanguages(): string[] {
        return this.rankEntries(this.analytics.map((l) => l.language));
    }
    private get rankedClickedRoutes(): string[] {
        return this.rankEntries(this.analytics.map((c) => c.route));
    }
    private get rankedUserIds(): string[] {
        return this.rankEntries(
            this.analytics.map((u) => (u.userId != null ? u.userId : "N/A"))
        );
    }
    private get rankedTimestamps(): string[] {
        return this.rankEntries(this.analytics.map((t) => t.timestamp));
    }

    private get rankedIpAddresses(): string[] {
        return this.rankEntries(this.analytics.map((i) => i.ipAddress));
    }
    private get rankedUserAgents(): string[] {
        return this.rankEntries(this.analytics.map((ua) => ua.ipAddress));
    }

    private rankEntries(entries: string[], returnedEntries = 5): string[] {
        const uniqueEntryObject: { [key: string]: number } = {};
        entries.forEach((entry) => {
            if (entry in uniqueEntryObject) {
                uniqueEntryObject[entry]++;
            } else {
                uniqueEntryObject[entry] = 1;
            }
        });

        return Object.entries(uniqueEntryObject)
            .sort((a, b) => b[1] - a[1])
            .map((entryObject) => entryObject[0])
            .slice(0, returnedEntries);
    }

    private async created() {
        try {
            this.analytics = await this.fetchAnalytics();
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    private async fetchAnalytics(): Promise<Analytics[]> {
        const response = (
            await axios.post<APIResponse<Analytics[]>>("/analytics")
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknowError");
        }
        return response.data;
    }
}
